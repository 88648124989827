<template>
  <div class="wrapper-loading">
    <img src="media/logos/logo-kemendagri.png" alt="" class="mb-5" />
    <b-spinner v-if="status === ''" large variant="primary"></b-spinner>
    <span v-if="status === 'error'">Gagal login...</span>
  </div>
</template>

<script>
import TokenService from "@/core/services/token.service";
import VueJwtDecode from "vue-jwt-decode";
import Swal from "sweetalert2";

export default {
  name: "polpumCallback",
  data() {
    return {
      resp: "",
      status: ""
    };
  },
  mounted() {
    setTimeout(() => {
      this.loginCallback();
    }, 3000);
  },
  methods: {
    loginCallback() {
      let data = JSON.parse(decodeURIComponent(this.$route.query.at));
      console.log(data);

      if (data.access_token === undefined) {
        // return data;
        console.log("Access token not found!");
        this.$store.commit("auth/loginFailure");
        return;
      }

      const requestOptionsSidat = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.access_token
        }
      };
      fetch(
        `${process.env.VUE_APP_URL_LOCAL}/token/generate-token-from-polpum-sso`,
        requestOptionsSidat
      )
        .then(r => r.json())
        .then(data => {
          console.log("Data returned from generate-token-from-polpum-sso");
          console.log(data);
          if (data.access_token) {
            TokenService.setUser(data);
            TokenService.setToken(data.access_token);

            // dispatch vuex
            this.$store.commit("auth/loginSuccess", data);
            const parsed_token = VueJwtDecode.decode(data.access_token);
            const hasPermissions = parsed_token.role;
            const parsed_refresh_token = VueJwtDecode.decode(
              data.refresh_token
            );
            // this.$store.commit("", parsed_token);
            this.$store.commit("token/setAttribute", hasPermissions);
            this.$store.commit("token/setToken", data.access_token);
            this.$store.commit("token/setParsedToken", parsed_token);
            this.$store.commit("token/setRefreshToken", data.refresh_token);
            this.$store.commit(
              "token/setParsedRefreshToken",
              parsed_refresh_token
            );
            if (parsed_token.role.id === 2) {
              this.$store.commit("token/setIsSuperAdmin", true);
            } else {
              this.$store.commit("token/setIsSuperAdmin", false);
            }

            this.$router.push({ name: "dashboard" });
          } else {
            this.$store.commit("auth/loginFailure");
          }

          // return data;
        })
        .catch(reason => {
          // return reason;
          console.log(reason);
          this.status = "error";
          this.resp = reason;
          Swal.fire({
            icon: "warning",
            title: reason,
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok"
          });
        });
    }
  }
};
</script>

<style scoped>
.wrapper-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: fixed;
}
</style>
